import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";

import { userReducer } from "./store/user/user.redux";
// css
import "./App.css";
import "./pages/auth/login.css";
import "react-phone-input-2/lib/style.css";
import "./components/products/myads/myproductitems.css";
import "./pages/user/dashboard/myads.css";
import "./pages/user/dashboard/create-post.css";
import "./pages/user/orders/orders-details.css";
import "./pages/product/productlist/listing-products.css";
import "./pages/user/account/user-profile-details.css";
import "./pages/seller/seller-profile.css";
import "./components/header/banner.css";
import "./components/header/header.css";
import "./components/lock/lock.css";
import "./components/notification/notification.css";
import "./components/products/post/recently-orders.css";
import "./pages/user/ad-promotion-plans/promotion.css";
import "./components/user/profile-verification/profile-verification.css";
import "./components/dashboard/default-dashboard.css";
import "./pages/about/about.css";

import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";

import Progress from "./components/pogress-line/Progress";

// Lazy loading components
const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const Faq = lazy(() => import("./pages/faq/Faq"));
const AboutUs = lazy(() => import("./pages/about/AboutUs"));
const MyAds = lazy(() => import("./pages/user/dashboard/MyAds"));
const TeamsAndConditions = lazy(() => import("./pages/TeamsAndConditions"));
const UserOrders = lazy(() => import("./pages/user/orders/UserOrders"));
const PlaceOrder = lazy(() => import("./pages/order/PlaceOrder"));
const ReceivedOrders = lazy(() => import("./pages/user/orders/ReceivedOrders"));
const CreateNewPost = lazy(() =>
  import("./pages/user/dashboard/CreateNewPost")
);
const UserDashboard = lazy(() =>
  import("./pages/user/dashboard/UserDashboard")
);
const MyFavoriteAds = lazy(() =>
  import("./pages/user/dashboard/MyFavoriteAds")
);
const UserProfileDetails = lazy(() =>
  import("./pages/user/account/UserProfileDetails")
);
const OrdersDetails = lazy(() => import("./pages/user/orders/OrdersDetails"));
const ProductDetail = lazy(() => import("./pages/product/ProductDetail"));
const HowItWorkPage = lazy(() => import("./pages/howitwork/HowItWorkPage"));
const SellerProfile = lazy(() => import("./pages/seller/SellerProfile"));
const UserChats = lazy(() => import("./pages/user/message/UserChats"));
const Verification = lazy(() => import("./pages/auth/Verification"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const ProfileVerification = lazy(() =>
  import("./components/user/profile-verification/ProfileVerification")
);
const ForgotPassPage = lazy(() => import("./pages/auth/ForgotPassPage"));
const ListingsProducts = lazy(() =>
  import("./pages/product/productlist/ListingsProducts")
);
const UploadDocumentCameraPage = lazy(() =>
  import("./pages/user/document/UploadDocumentCameraPage")
);
const FeaturesPage = lazy(() => import("./pages/Features/FeaturesPage"));
const UpdatePost = lazy(() => import("./pages/user/dashboard/UpdatePost"));
const WebBrowser = lazy(() =>
  import("./components/user/profile-verification/WebBrowser")
);
const DocumentUploadComponents = lazy(() =>
  import("./components/user/profile-verification/DocumentUploadComponents")
);
const PaymentsDetails = lazy(() =>
  import("./pages/user/payment/PaymentsDetails")
);
const VerificationPhoneNumber = lazy(() =>
  import("./pages/auth/VerificationPhoneNumber")
);
const MyNotification = lazy(() =>
  import("./pages/user/mynotification/MyNotification")
);
const UploadOrderImagesByQR = lazy(() =>
  import("./pages/order/orderPhotos/UploadOrderImagesByQR")
);
const PaymentSettings = lazy(() =>
  import("./pages/user/payment/PaymentSettings")
);
const CouponsPage = lazy(() => import("./pages/coupons/CouponsPage"));
const Feedback = lazy(() => import("./pages/feedback/Feedback"));

// css

const TRACKING_ID = "G-ER1X6DBR65"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const clientId =
  "88613061289-h21s9prd35oka29cbb2vrcg56m32elsb.apps.googleusercontent.com";

const App = () => {
  const rootReducer = combineReducers({
    userReducer: userReducer,
  });

  const myStore = configureStore({
    reducer: rootReducer,
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.pathname,
    });
  }, []);

  return (
    <Suspense fallback={<Progress />}>
      <GoogleOAuthProvider clientId={clientId}>
        <Provider store={myStore}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/verification" element={<Verification />} />
              <Route
                path="/user/phone/update"
                element={<VerificationPhoneNumber />}
              />
              <Route path="/forgot-password" element={<ForgotPassPage />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/secure-transactions" element={<FeaturesPage />} />
              <Route
                path="/terms-and-conditions"
                element={<TeamsAndConditions />}
              />
              <Route path="/how-it-works" element={<HowItWorkPage />} />
              <Route path="/user/notifications" element={<MyNotification />} />
              <Route path="/user/dashboard" element={<UserDashboard />} />
              <Route path="/user/post/create" element={<CreateNewPost />} />
              <Route path="/user/post/edit/:id" element={<UpdatePost />} />
              <Route path="/ads/listings" element={<ListingsProducts />} />
              <Route
                path="/ads/listings/:category"
                element={<ListingsProducts />}
              />
              <Route path="/ads/:slug/:id" element={<ProductDetail />} />
              <Route path="/user/ads" element={<MyAds />} />
              <Route
                path="/ads/:slug/:id/placeorder"
                element={<PlaceOrder />}
              />
              <Route path="/user/orders" element={<UserOrders />} />
              <Route path="/user/coupon" element={<CouponsPage />} />
              <Route
                path="/user/orders/details/:orderNumber"
                element={<OrdersDetails />}
              />
              <Route
                path="/user/sell-rent/orders"
                element={<ReceivedOrders />}
              />
              {/* <Route
                path="/user/profile/verify"
                element={<ProfileVerification />}
              /> */}
              <Route
                path="/user/profile/verify/country/:token"
                element={<WebBrowser />}
              />
              <Route
                path="/user/order/media/upload/:user/:order/:type"
                element={<UploadOrderImagesByQR />}
              />
              <Route
                path="/user/document-verification/:token"
                element={<UploadDocumentCameraPage />}
              />
              <Route
                path="/user/document-verification/proof/:token"
                element={<UploadDocumentCameraPage />}
              />
              <Route
                path="/user/document-upload/thankyou"
                element={<DocumentUploadComponents />}
              />
              <Route
                path="/user/profile/settings"
                element={<UserProfileDetails />}
              />
              <Route
                path="/user/payment/details"
                element={<PaymentsDetails />}
              />
              <Route
                path="/user/payment/settings"
                element={<PaymentSettings />}
              />
              <Route
                path="/user/payment/settings/:account"
                element={<PaymentSettings />}
              />
              <Route
                path="/seller/:slug/:id/profile"
                element={<SellerProfile />}
              />
              <Route path="/user/favorite" element={<MyFavoriteAds />} />
              <Route path="/user/messages" element={<UserChats />} />
              <Route path="/user/messages/:id" element={<UserChats />} />
              <Route
                path="/feedback/:user/:order/:token"
                element={<Feedback />}
              />
            </Routes>
          </BrowserRouter>
        </Provider>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </GoogleOAuthProvider>
    </Suspense>
  );
};

export default App;
